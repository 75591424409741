<template>
  <v-form ref="form1">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>仕入先検索</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <!-- 仕入先コード -->
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="supplierCode"
                    label="仕入先コード"
                    :items="supplierList"
                    item-text="comboItem"
                    item-value="code"
                    clearable
                    :hint="`完全一致`"
                    persistent-hint
                    ref="supplierCodeComboBox"
                    @change="updateSupplierCombo"
                  >
                  </v-autocomplete>
                </v-col>
                <!-- 仕入先名(カナ)-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="supplierKanaName"
                    label="仕入先名(カナ)"
                    clearable
                    :rules="[limit_length50]"
                    :hint="`部分一致`"
                    persistent-hint
                    counter="50"
                    @change="clearSupplierCode"
                    :disabled="isDisableText"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <!-- 検索ボタン -->
            <v-btn color="secondary" v-on:click="onClickSearch" rounded>
              <v-icon left> mdi-magnify </v-icon>検索
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="supplierItems"
              class="elevation-5 mt-5 scroll-lock-supplier-search"
              item-key="supplierCode"
              multi-sort
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>検索結果</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-toolbar-title>
                    {{ supplierItems.length }}件
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- 新規登録ボタン -->
                  <v-btn dark color="secondary" rounded @click="insertItem()">
                    <v-icon left>mdi-pen-plus</v-icon>新規登録
                  </v-btn>
                </v-toolbar>
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-container>
                  <v-row>
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on, attrs }">
                        <template v-if="transitionFlg == true">
                          <v-btn
                            class="m-0"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item)"
                          >
                            <v-icon>mdi-checkbox-marked-circle </v-icon>
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn
                            class="m-0"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(item)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn></template
                        ></template
                      >
                      <span>{{ tooltipText }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <template v-if="transitionFlg == true"> </template>
                        <template v-else>
                          <v-btn
                            class="ml-1"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteItem(item)"
                            ><v-icon>mdi-delete</v-icon>
                          </v-btn></template
                        ></template
                      >
                      <span>削除</span>
                    </v-tooltip>
                  </v-row>
                </v-container>
                <v-spacer></v-spacer>
              </template>
              <template v-slot:no-data> </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import { listM_SHIIRESAKI } from "@/graphql/queries";
import { executeTransactSql } from "@/graphql/mutations";
import { CONFIRM_MESSAGE_DELETE_DATA, ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "SupplierSearchArea",
  props: {
    transitionFlg: Boolean,
    reqSupplierCode: Number,
  },
  data: () => ({
    // 削除ダイアログ
    dialogDelete: false,
    // 編集インデックス
    editedIndex: -1,
    // 検索用テキストボックス無効化フラグ
    isDisableText: false,
    // Datatablesのヘッダー
    headers: [
      { text: "操作", value: "actions", sortable: false, width: "100px" },
      { text: "仕入先コード", value: "supplierCode", width: "50px" },
      { text: "仕入先名(カナ)", value: "supplierKanaName", width: "100px" },
      { text: "仕入先名", value: "supplierName", width: "100px" },
      { text: "CSS仕入先コード", value: "supplierCssCode", width: "50px" },
    ],
    // 仕入先名(カナ)
    supplierKanaName: null,
    // 仕入先コード
    supplierCode: null,
    // 仕入先検索用
    supplierList: [],
    // Datatablesのデータ
    supplierItems: [],

    // ログインユーザー情報
    loginUserInfo: null,

    // 入力規則
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      value == null || value.length <= 50 || "50文字以内で入力してください",
  }),
  /**
   * computed 算出プロパティ
   */
  computed: {
    /**
     * ツールチップタイトル設定
     */
    tooltipText() {
      return this.transitionFlg === true ? "選択" : "修正";
    },
  },
  /**
   * created ライフサイクルフック
   */
  created: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (this.loginUserInfo !== null);
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // TODO 見積入力画面より遷移した場合、元の仕入先コードを取得する。
      // if (this.reqSupplierCode) {
      //   this.supplierCode = this.reqSupplierCode;
      //   await this.onClickSearch();
      // }
      // コンボボックス用リストの取得
      // 検索条件
      let condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY SHIIRESAKI_CD ASC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHIIRESAKI;
          this.supplierList = [];
          for (const data of resultData) {
            this.supplierList.push({
              comboItem: data.SHIIRESAKI_CD + "：" + data.SHIIRESAKI_NAME,
              code: data.SHIIRESAKI_CD,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          console.log("データ0件ログ");
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log("異常系ログ:" + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        // ローディング画面の解除
        return false;
      }
      // 検索条件の初期化
      this.supplierKanaName = null;
      this.supplierCode = null;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },

    /**
     * 新規登録ボタン押下時処理
     */
    insertItem() {
      // メソッド名を定義
      const method_name="insertItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.$router.push({
        name: "SupplierInsert",
        params: { isRequiredDc: true },
      });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ボタン押下時処理
     */
    editItem(item) {
      // メソッド名を定義
      const method_name="editItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 入力画面に編集対象の仕入先コードを渡す
      this.$router.push({
        name: "SupplierInsert",
        query: {
          reqSupplierCode: item.supplierCode,
        },
      });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 選択ボタン押下時処理
     */
    selectItem(item) {
      // メソッド名を定義
      const method_name="selectItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.editedItem = Object.assign({}, item);
      // 見積入力画面に渡すデータを要修正
      this.$emit("result", {
        returnNo: this.reqSupplierCode,
        returnSupplierCode: item.supplierCode,
        // supplierCode: this.editedItem.supplierCode,
        // supplierKanaName: this.editedItem.supplierKanaName,
        // supplierName: this.editedItem.supplierName,
      });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 削除ボタン押下時処理
     */
    async deleteItem(item) {
      // メソッド名を定義
      const method_name="deleteItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      console.log(JSON.stringify(item));
      this.editedIndex = this.supplierItems.indexOf(item);
      if (confirm(CONFIRM_MESSAGE_DELETE_DATA)) {
        // ローディング画面の開始
        this.$store.commit("setLoading", true);
        // 削除条件 ※設定データは画面に応じて差し替えること。
        let sqlList = [
          "DELETE FROM M_SHIIRESAKI " +
            " WHERE BUMON_CD = " +
            item.departmentCode +
            " AND   SHIIRESAKI_CD = " +
            item.supplierCode,
        ];
        let condition = {
          SQLs: sqlList,
        };

        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // 実行結果取得
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(executeTransactSql, condition)
          );
          // レスポンスデータを取得できた際の処理
          if (result) {
            const responseBody = JSON.parse(
              result.data.executeTransactSql.body
            );

            // SQL実行でエラーが発生した場合の処理
            if (result.data.executeTransactSql.statusCode > 200) {
              console.info({ responseBody });
              // レスポンスメッセージ
              let message = responseBody.message;
              console.error({ message });
              // エラー内容
              const errorMessage = responseBody.error;
              console.error({ errorMessage });
              // ログ出力(Lamda関数でのSQL実行でステータスエラー)
              addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            } else {
              // SQLが正常に終了した際の処理
              if (responseBody.data) {
                // SELECT文の結果はresponseBody.dataとして返却される
                // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
                for (const rows of responseBody.data) {
                  console.dir(rows, { depth: null });
                }
                // 検索結果からアイテムを削除
                this.supplierItems.splice(this.editedIndex, 1);
              }
              // ログ出力(SQL-end_OK)
              addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            }
            console.info(JSON.parse(result.data.executeTransactSql.body));
          } else {
            // レスポンスデータを取得できなかった際の処理
            console.log("deleteTables : " + JSON.stringify(result));
            // ログ出力(Lamda関数でのSQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        } catch (error) {
          console.log("deleteTables : 異常終了 = " + JSON.stringify(error));
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }

        // 初期化処理の実行
        if (!(await this.initialize())) {
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 検索条件の初期化
        this.updateSupplierCombo();
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 検索ボタン押下時
     */
    async onClickSearch() {
      // メソッド名を定義
      const method_name="onClickSearch"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 検索結果格納用リストの初期化
      this.supplierItems = [];
      // 検索条件 ※${部門コード}はログインユーザの所属部門を取得し設定すること。
      // 初期値は全件検索
      let condition = {
        where_options: "AND BUMON_CD = " + this.loginUserInfo.BUMON_CD + " ORDER BY SHIIRESAKI_CD",
      };
      if (this.supplierKanaName && !this.supplierCode) {
        // 名前検索の場合は部分一致
        condition = {
          where_options:
            "AND BUMON_CD = " +
            this.loginUserInfo.BUMON_CD +
            " AND SHIIRESAKI_KANA_NAME LIKE '%" +
            this.supplierKanaName +
            "%'" + " ORDER BY SHIIRESAKI_CD",
        };
      } else if (this.supplierCode) {
        // コード検索の場合は完全一致
        condition = {
          where_options:
            "AND BUMON_CD = " +
            this.loginUserInfo.BUMON_CD +
            " AND SHIIRESAKI_CD = " +
            this.supplierCode,
        };
      }
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );

        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          let resultData = result.data.listM_SHIIRESAKI;
          for (const data of resultData) {
            this.supplierItems.push({
              departmentCode: data.BUMON_CD,
              supplierCode: data.SHIIRESAKI_CD,
              supplierName: data.SHIIRESAKI_NAME,
              supplierKanaName: data.SHIIRESAKI_KANA_NAME,
              supplierCssCode: data.CSS_SHIIRESAKI_CD,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          console.log("データ0件ログ");
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
      }

      // ローディング画面を解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * コードのコンボボックス更新時処理
     */
    updateSupplierCombo() {
      // メソッド名を定義
      const method_name="updateSupplierCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // テキストボックスの無効化
      this.isDisableText = null !== this.supplierCode;
      this.supplierKanaName = null;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 仕入先名（カナ）テキストボックス更新時処理
     */
    clearSupplierCode() {
      // メソッド名を定義
      const method_name="clearSupplierCode"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.supplierCode = "";
      this.isDisableText = false;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.scroll-lock-supplier-search table th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}
.scroll-lock-supplier-search table td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
/* 2列目スクロール時固定 */
.scroll-lock-supplier-search table th:nth-child(2) {
  position: sticky;
  left: 72px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.scroll-lock-supplier-search table td:nth-child(2) {
  position: sticky;
  left: 72px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.scroll-lock-supplier-search table th:nth-child(3) {
  position: sticky;
  left: 152px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.scroll-lock-supplier-search table td:nth-child(3) {
  position: sticky;
  left: 152px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 1列目改行禁止 */
.scroll-lock-supplier-search table td:nth-child(1) {
  white-space: nowrap;
}
</style>
